@font-face {
  font-family: 'Montserrat Medium';
  src: url('fonts/Montserrat-Medium.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Montserrat Regular';
  src: url('fonts/Montserrat-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat Thin';
  src: url('fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Montserrat SemiBold';
  src: url('fonts/Montserrat-SemiBold.ttf');
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

details {
  padding-left: 30px;
  position: relative;
  font-size: 15px;
  font-weight: 300;
}

ul li::marker {
  color: red;
}

details {
  margin-bottom: 12px;
  color: #01127e;
}

details summary {
  cursor: pointer;
  margin-bottom: 12px;
  position: relative;
  font-weight: 600;
  font-family: 'Montserrat Medium';
}

li::marker {
  color: red;
}

details summary::before {
  font-size: 21px;
  /*display: inline-block;*/
  position: absolute;
  color: red;
  top: -5px;
  left: -20px;
  content: '+';
  transform: rotate(0deg);
  transition: 0.2s;
  font-weight: 400;
}

details summary:hover::before {
  transform: scale(1.3);
}

details summary::-webkit-details-marker,
details summary::marker {
  display: none;
  content: "";
}

details[open]>summary::before {
  transform: rotate(495deg)
}

details img {
  max-width: 100%;
  max-height: 300px;
  display: block;
}
